import React from 'react'
import * as S from './style'
import ButtonApp from '../../_components/_ButtonApp'

const imgBackground = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-hero/image.webp'
const imgHero = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-hero-logo/image.webp'

type HeroProps = {
  openModal: () => void;
}

const Hero = ({ openModal }: HeroProps) => {
  return (
    <S.Section backgroundImage={imgBackground}>
      <div className='container'>
        <div className='content-info'>
          <div className='content-img'>
            <img src={imgHero} alt='Feirão da Divida Zero' />
          </div>
          <h1 className='font-citrina'>Escolha Inteligente é recuperar sua vida financeira</h1>
          <p>O Feirão Dívida Zero é o melhor momento do ano para negociar sua pendência com o Inter com condições exclusivas.</p>
          <p>Até 31 de dezembro de 2024.</p>

          <ButtonApp
            url='bancointer://home?utm_campaign=lp_feiraodividazero_2024'
            title='Saiba sua oferta'
            textButton='Saiba sua oferta'
            openModal={openModal}
            dataLayer={{
              section: 'dobra_01',
              section_name: 'Escolha Inteligente é recuperar sua vida financeira',
              element_action: 'click button',
              element_name: 'Saiba sua oferta',
            }}
          />
        </div>
      </div>
    </S.Section>
  )
}
export default Hero
